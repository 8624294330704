/**
 * Esse regex aceita letras, numeros, espaços e os caracteres especiais: ?!,.:;'+=-
 *
 *  verifica se há pelo menos 1 número em qualquer posição da string
 *
 * verifica se há pelo menos 1 caracter especial em qualquer posição da string
 *
 * verifica se há pelo menos 8 caracteres na string
 *
 * a ideia dele é ser usado para validar senhas.
 */
export const SENHA_REGEX = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
