import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /* background-color: #f5f5f5; */
  background-color: #333;
  padding: 1rem;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  gap: 1.5rem;

  h2 {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .input {
    width: 100%;
  }

  button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
  }
`
