// Arquivo criado: 24/10/2023 às 16:37
import React from 'react'
import * as S from './styles'
import { useDispatch } from 'react-redux'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'

interface Data {
  id: number
  active: boolean
  images: string[]
  link?: string
}

export const Banner = (): JSX.Element => {

  const dispatch = useDispatch()
  const [component, setComponent] = React.useState<Data[]>([])
  const [get, setGet] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [id, setId] = React.useState<number | null>(null)
  const [active, setActive] = React.useState(false)
  const [link, setLink] = React.useState('')
  const [openCreateCompra, setOpenCreateCompra] = React.useState(false)

  const [file, setFile] = React.useState<File | undefined>(undefined)
  const [loadingModal, setLoadingModal] = React.useState(false)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'OrçaBeer - Banner'

      setLoading(true)
      const response = await backEnd('GET', 'banner/admin')
      setLoading(false)

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      }
      setComponent(response.data)

      if (get) {
        setGet(false)
      }
    }
    void getData()
  }, [dispatch, get])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const handleOpenCreateCompra = (): void => {
    setOpenCreateCompra(true)
  }

  const handleCloseCreateCompra = (): void => {
    setOpenCreateCompra(false)
    setLink('')
    setId(null)
    setActive(false)
    setFile(undefined)
  }

  const handleCreateCompra = async (): Promise<void> => {

    let base64

    if (!file && !id) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Por favor, selecione uma imagem para continuar',
        type: 'error'
      }))

      return
    }

    if (file) {
      base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file as Blob)
        reader.onload = () => { resolve(reader.result) }
        reader.onerror = error => { reject(error) }
      })

      if (!base64) {
        dispatch(setOpenGlobalMessageAction({
          message: 'Ocorreu um erro ao tentar enviar a imagem. Por favor, tente novamente',
          type: 'error'
        }))

        return
      }
    }

    const body = {
      ...file && {
        files: [
          {
            base64,
            size: file.size,
            name: file.name,
            type: file.type
          }
        ]
      },
      banner: {
        active,
        link: link || undefined
      }
    }

    setLoadingModal(true)

    const method = id ? 'PUT' : 'POST'
    const url = id ? `banner/${id}` : 'banner'
    const response = await backEnd(method, url, body)
    setLoadingModal(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: response.msg || 'Efetuado com sucesso!',
      type: 'success'
    }))

    setOpenCreateCompra(false)
    setId(null)
    setLink('')
    setFile(undefined)
    setGet(true)
  }

  const uploadImageValidation = (file: File | undefined): void => {
    if (file) {

      const isImage1 = /(\.jpeg)$/i
      const isImage2 = /(\.png)$/i
      const isImage3 = /(\.jpg)$/i

      if (!isImage1.exec(file.name) && !isImage2.exec(file.name) && !isImage3.exec(file.name)) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O arquivo selecionado deve possuir extensão .jpg, .jpeg ou .png',
          type: 'error'
        }))

        return
      }

      if (file.name.length > 255) {
        dispatch(setOpenGlobalMessageAction({
          message: 'O nome do arquivo selecionado é maior que 255 caracteres',
          type: 'error'
        }))

        return
      }

      const fileSize = file.size

      if (fileSize > 2000000) {
        dispatch(setOpenGlobalMessageAction({
          message: 'A imagem selecionada é muito grande. O tamanho máximo é de 2MB',
          type: 'error'
        }))

        return
      }

      setFile(file)
    }
  }

  const handleEditCompra = async (data: Data): Promise<void> => {

    setId(data.id)
    setActive(data.active)
    setLink(data.link ?? '')
    setOpenCreateCompra(true)

  }

  return (
    <S.Container>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={openCreateCompra}
        onClose={handleCloseCreateCompra}
      >
        <DialogTitle id="alert-dialog-title">
          {id ? 'Editar' : 'Criar novo'} Banner
        </DialogTitle>
        <S.DialogContent>
          {
            loadingModal
              ? (
                <S.LoadingContainer>
                  <CircularProgress />
                </S.LoadingContainer>
              )
              : (
                <>
                  <S.RowContainer>
                    <S.ColumnContainer width={99}>
                      <S.RowContainer style={{ justifyContent: 'flex-start', marginBottom: 15 }}>
                        <h5>Ativo?</h5>
                        <Switch
                          checked={active}
                          onChange={() => { setActive(!active) }}
                          style={{ alignSelf: 'center' }}
                        />
                      </S.RowContainer>
                      <S.RowContainer className='inputCreate'>
                        <TextField type='url' fullWidth className='input' size='small' id='link' name='link' value={link} onChange={(e) => { setLink(e.target.value) }} label='Link' />
                      </S.RowContainer>
                    </S.ColumnContainer>
                  </S.RowContainer>
                  <S.RowContainer>
                    <S.SelectFileBox>
                      <label htmlFor='file'>SELECIONAR {id && 'NOVA'} IMAGEM</label>
                      <input id='file' name='file' type='file' value={''} required accept='image/png, image/jpg, image/jpeg' placeholder='Anexar Arquivo' onChange={(e) => { uploadImageValidation(e.target.files?.[0]) }} />
                    </S.SelectFileBox>
                  </S.RowContainer>
                  {
                    (file) && (
                      <>
                        <S.RowContainer style={{ justifyContent: 'center', backgroundColor: '#F1B61744', borderRadius: 8, marginBottom: 10 }}>
                          <p><strong>Arquivo selecionado:</strong> {file.name}</p>
                        </S.RowContainer>
                        <S.RowContainer style={{ justifyContent: 'space-evenly' }}>
                          <Button variant='outlined' onClick={() => { setFile(undefined) }}>Excluir</Button>
                        </S.RowContainer>
                      </>
                    )
                  }
                </>
              )
          }
        </S.DialogContent>
        <DialogActions>
          <Button onClick={handleCreateCompra}>Salvar</Button>
          <Button onClick={handleCloseCreateCompra}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <>
                <S.RowContainer className='top'>
                  {
                    component.length > 0 && (
                      <h4>Fo{component.length !== 1 ? 'ram' : 'i'} encontrado{component.length !== 1 ? 's' : ''} {component.length} banner{component.length !== 1 ? 's' : ''}:</h4>
                    )
                  }
                  <Button variant='outlined' onClick={handleOpenCreateCompra}>{id ? 'Editar' : 'Criar novo'} Banner</Button>
                </S.RowContainer>
                {
                  component.length > 0
                    ? (
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }}>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell><Button>Situação</Button></StyledTableCell>
                              <StyledTableCell><Button>Imagem</Button></StyledTableCell>
                              <StyledTableCell><Button>URL</Button></StyledTableCell>
                              <StyledTableCell><Button>Editar</Button></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              component.map((item, index) => {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align="left">{item.active ? 'Ativo' : 'Desativado'}</StyledTableCell>
                                    <StyledTableCell align="left"><a href={item.images[0]} target='_blank' rel="noreferrer">{item.images[0] ? 'Abrir imagem' : '-'}</a></StyledTableCell>
                                    <StyledTableCell align="left"><a href={item.link} target='_blank' rel="noreferrer">{item.link ? item.link : '-'}</a></StyledTableCell>
                                    <StyledTableCell align="center"><Button className='icon' onClick={() => { void handleEditCompra(item) }}><ModeOutlinedIcon /></Button></StyledTableCell>
                                  </StyledTableRow>
                                )
                              })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )
                    : (
                      <h4>Nenhum banner encontrado</h4>
                    )
                }
              </>
            )
        }

      </S.ScreenContainer>
    </S.Container>
  )
}
