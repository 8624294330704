// Arquivo criado: 19/11/2024 às 22:26
import React from 'react'
import * as S from './styles'
import TextField from '@mui/material/TextField'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import Button from '@mui/material/Button'
import { SENHA_REGEX } from '../../utils/regex/senhaRegex'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { backEnd } from '../../utils/backend.util'

export const RecuperarSenha = (): JSX.Element => {

  const { search } = useLocation()

  const [password, setPassword] = React.useState<string>('')
  const [confirmPassword, setConfirmPassword] = React.useState<string>('')

  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [link, setLink] = React.useState<string | null>(null)

  const dispatch = useDispatch()

  React.useEffect(() => {
    document.title = 'Recuperar senha'
  }, [])

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {

    e.preventDefault()

    if (!password) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Digite uma senha com no mínimo 8 caracteres para continuar',
        type: 'error'
      }))
      return
    }

    if (!password.match(SENHA_REGEX)) {
      dispatch(setOpenGlobalMessageAction({
        message: 'A senha deve conter pelo menos 1 número, 1 caracter especial e 8 caracteres ao todo',
        type: 'error'
      }))
      return
    }

    if (!confirmPassword) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Digite novamente sua senha para confirmar e continuar',
        type: 'error'
      }))
      return
    }

    if (password !== confirmPassword) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Digite a mesma senha em ambos os campos para continuar',
        type: 'error'
      }))
      return
    }

    const params = new URLSearchParams(search)
    const token = params.get('token')

    if (!token) {
      dispatch(setOpenGlobalMessageAction({
        message: 'Token inválido. Tente novamente',
        type: 'error'
      }))
      return
    }

    setLoading(true)
    const response = await backEnd('PUT', '/usuario/mudar-senha-token', {
      token,
      senha: btoa(password)
    })

    if (!response.ok) {
      setLoading(false)
      dispatch(setOpenGlobalMessageAction({
        message: response.msg,
        type: 'error'
      }))

      return
    }

    setLink(`orcabeer://--/login?token=${token}`)

  }

  return (
    <S.Container>
      <S.Form>

        {
          link
            ? (
              <>
                <h2>Tudo certo por aqui!</h2>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { window.location.href = link }}
                >
                  Abrir App
                </Button>
              </>
            )
            : (
              <>
                <h2>Cadastre sua nova senha</h2>

                <TextField
                  className="input"
                  required
                  id="senha"
                  name="senha"
                  label="Senha"
                  type={showPassword ? 'text' : 'password'}
                  autoFocus
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                  sx={{
                    '& .MuiInputLabel-root': { color: '#000' },
                    '& .MuiInputLabel-root.Mui-focused': { color: '#F1B617' }
                  }}
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setShowPassword(!showPassword) }}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <TextField
                  className="input"
                  required
                  id="confirm-senha"
                  name="confirmSenha"
                  label="Confirme a Senha"
                  value={confirmPassword}
                  disabled={loading}
                  onChange={(e) => { setConfirmPassword(e.target.value) }}
                  type={showConfirmPassword ? 'text' : 'password'}
                  sx={{
                    '& .MuiInputLabel-root': { color: '#000' },
                    '& .MuiInputLabel-root.Mui-focused': { color: '#F1B617' }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? 'Carregando...' : 'Cadastrar'}
                </Button>
              </>
            )
        }
      </S.Form>
    </S.Container>
  )
}
